import React from 'react'
import WhiteButton from './WhiteButton'
import { navigate } from 'gatsby'

function WhiteLinkButton(props) {
  return <WhiteButton {...props} onClick={e => { e.preventDefault(); navigate(props.href) }}>{props.children}</WhiteButton>
}  

export default WhiteLinkButton

