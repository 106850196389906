import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles'
import NewsPost from './NewsPost'

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  news: {
    '&> *': { marginBottom: "50px" },
    '&:last-child': { marginBottom: "0px" }
  }
}))

const News = (props) => {
  const classes = useStyles()

  let posts = props.posts.map(p => <NewsPost key={p.node.id} post={p} />)

  return (
    <React.Fragment>
      <div className={classes.news}>
        {posts}
      </div>
    </React.Fragment>
  )
}

export default News