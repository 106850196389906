import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  bannerImageContainer: {
    width:"100%", 
    // height: "100vw", 
    // maxHeight: "450px", 
    overflow: "hidden", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // [theme.breakpoints.down('xs')]: {
    //   maxHeight: "200px",
    // },
  },
  bannerImage: {
    alignSelf: "center", 
    display: "block",
    width: "100%", 
    height: "auto"
  }
}))

const BannerImage = ({image, imageStyles, children, className, ...props}) => {
  const classes = useStyles()
  
  return (
    <React.Fragment>
      <div className={clsx(classes.bannerImageContainer, className)} {...props}>
        {props.prepend}
        <img src={image} alt="Header" className={classes.bannerImage} style={imageStyles} />
        {children}
      </div>
    </React.Fragment>
  )
}

export default BannerImage