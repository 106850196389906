import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'

let whiteStyles = withStyles(theme => { return {
  root: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "0",
    boxShadow: 'none',
    fontSize: 20,
    textAlign: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    '&:hover': {
      backgroundColor: '#aaaaaa',
    },
    '&:active': {
      backgroundColor: '#aaaaaa',
    },
    '&:focus': {
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    },
  },      
}})

// GA code adapted from https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/index.js
function WhiteButton(props) {
  const { gaCategory, ...rest } = props

  const onClick = e => {
    if (typeof props.onClick === `function`) {
      props.onClick(e)
    }
    let redirect = true
    if (
      e.button !== 0 ||
      e.altKey ||
      e.ctrlKey ||
      e.metaKey ||
      e.shiftKey ||
      e.defaultPrevented
    ) {
      redirect = false
    }
    if (props.target && props.target.toLowerCase() !== `_self`) {
      redirect = false
    }
    if (gaCategory && window.ga) {
      window.ga(`send`, `event`, {
        eventCategory: gaCategory,
        eventAction: `click`,
        eventLabel: props.href,
        transport: redirect ? `beacon` : ``,
        hitCallback: function() {
          if (redirect) {
            document.location = props.href
          }
        },
      })
    } else {
      if (redirect) {
        document.location = props.href
      }
    }

    return false
  }

  return <Button {...rest} onClick={onClick}>{rest.children}</Button>
}  

export default whiteStyles(WhiteButton)

