import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LinkButton from './LinkButton'
import { observer } from 'mobx-react-lite'

const FooterLinkButton = props => <LinkButton {...props} 
    style={{color: "#21CAD2", borderRadius: "0", fontSize: "0.8rem", textTransform: "none"}} />

const useStyles = makeStyles(theme => ({
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: "initial",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none"
  },
  toolbar: {
    height: "48px",
    minHeight: "48px"
  }
}));

const Footer = observer(() => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <AppBar className={classes.footer}>
        <Toolbar className={classes.toolbar} style={{alignItems: "stretch"}}>
          <div style={{flexGrow: 1}} />
          <FooterLinkButton href="/terms">Terms</FooterLinkButton>
          <FooterLinkButton href="/privacy">Privacy</FooterLinkButton>
          <FooterLinkButton href="/about">© Jellygon, LLC</FooterLinkButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
})

export default Footer