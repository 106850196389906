import React from 'react'
import jellygonLogo from '../images/jellyfish_with_logo_large_glow.png'
import starsteadersLogo from '../images/Starsteaders Logo.png'
import LinkLink from '../components/LinkLink'
import WhiteLinkButton from '../components/WhiteLinkButton'
import {Container, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import "react-image-gallery/styles/css/image-gallery.css"
import styles from '../styles';
import News from '../components/News'
import { graphql } from "gatsby"
import { observer } from 'mobx-react-lite'
import Spacer from '../components/Spacer'
import BannerImage from '../components/BannerImage'
import Footer from '../components/Footer'

const useStyles = makeStyles(theme => { return {
  ...styles(theme),
  goalProgressText: {
    display: "flex", 
    position: "absolute", 
    top: "0", 
    left: "0", 
    width: "100%", 
    height: "100%", 
    justifyContent: "center", 
    alignItems: "center",
    fontSize: "20pt",
    fontWeight: "bold",
    color: "white"
  },
  jellygonSplash: {
    background: "#051320",
    padding: "50px 25px 50px 25px",
    [theme.breakpoints.down('xs')]: {
      padding: "25px 25px 25px 25px",
    },
  },
  starsteadersSplash: {
    // background: "#140d2c",
    padding: "125px 25px 125px 25px",
    marginBottom: "50px",
    [theme.breakpoints.down('xs')]: {
      padding: "50px 25px 50px 25px",
      marginBottom: "0",
    },
  },
}})

const IndexPage = observer(({
      data: {
        allMarkdownRemark: { edges },
      },
    }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <BannerImage image={jellygonLogo} imageStyles={{maxWidth: "500px"}} className={classes.jellygonSplash}>
          <Container maxWidth="md" style={{marginTop: "40px", marginBottom: "20px", color: "white"}}>
            <Container maxWidth="md" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Typography variant="h5" align="center" style={{ color: '#A569E3' }}>
                <b style={{ color: '#21CAD2' }}>Jellygon</b> is <b style={{ color: '#21CAD2' }}>making video games</b> in <b style={{ color: '#21CAD2' }}>Portland, Oregon</b>.
              </Typography>
              <Spacer amount="50px" />
              {/* <Typography variant="h6" style={{fontWeight: "initial"}} align="center">New here?  Read this first:</Typography>
              <Spacer amount="30px" />
              <WhiteLinkButton href="/whatisthis" size="large">Help me make Starsteaders!</WhiteLinkButton> */}
            </Container>
          </Container>
        </BannerImage>
        <BannerImage image={starsteadersLogo} imageStyles={{maxWidth: "700px"}} className={classes.starsteadersSplash}>
          <Container maxWidth="md" style={{marginTop: "40px", color: "white"}}>
            <Container maxWidth="md" style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <Spacer amount="50px" />
              <Typography variant="h6" style={{fontWeight: "initial"}} align="center">
                Explore, farm and build.  Alone or with friends.  On planets or in your spaceship.  
                Set in a persistent online universe.  You can play for free while things get started, 
                but in the future only sponsors at a certain level will be able to play!
              </Typography>
              <Spacer amount="75px" />
              <WhiteLinkButton href="/starsteaders" size="large">More Information</WhiteLinkButton>
            </Container>
          </Container>
        </BannerImage>
        <Container maxWidth="md" className={classes.lightContainer}>
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography variant="h4">LATEST NEWS</Typography>
            <Typography variant="body1"><LinkLink color="secondary" href="/news">MORE NEWS</LinkLink></Typography>
          </div>
        </Container>
        <Spacer amount="50px" />
        <News posts={edges} />
        <Footer />
      </div>
    </React.Fragment>
  )
})

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          html
          fields { slug }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            image
          }
        }
      }
    }
  }
`