const styles = theme => { return {
  root: {
    // backgroundImage: "url('Jellygon Swarm.png')",
    // backgroundSize: "60%",
    // backgroundRepeat: "true",
    // backgroundPositionX: "-100px",
    // backgroundPositionY: "-30px",
    color: "#505050",
    // this padding is to account for the footer (48px) plus a little more (50px) on desktop view
    paddingBottom: "98px",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "48px"
    },
    flexGrow: 1, // works on chrome
    minHeight: "100%", // works on firefox
    position: "relative"
  },
  lightContainer: {
    background: "#fff",
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 5),
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    lineHeight: "initial",
    display: "flex",
    alignItems: "center",
    flexGrow: "1"
  },
  emailLink: {
    color: theme.palette.secondary.main
  }
}}

export default styles