import React from 'react'
import Typography from '@material-ui/core/Typography'
import LinkLink from './LinkLink'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import styles from '../styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => { return {
  ...styles(theme),
  newsBannerImage: { marginBottom: "20px" },
  newsPost: {
    '& img': {
      maxWidth: "100%" 
    }
  }
}})

export default function NewsPost({ post }) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Container maxWidth="md" className={clsx(classes.lightContainer, classes.newsPost)}>
        {post.node.frontmatter.image ? <img className={classes.newsBannerImage} src={post.node.frontmatter.image} /> : null}
        <Typography variant="body2" style={{textTransform: "uppercase"}}>{post.node.frontmatter.date}</Typography>
        <Typography variant="h5" style={{textTransform: "uppercase"}}>
          <LinkLink noUnderline={true} color="primary" href={post.node.fields.slug}>{post.node.frontmatter.title}</LinkLink>
        </Typography>
        {/* can't use dangerouslySetInnerHTML inside <p> (Typography) tags: https://github.com/gatsbyjs/gatsby/issues/11108 */}
        <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: post.node.html }} />
      </Container>
    </React.Fragment>  
  )
}